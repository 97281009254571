import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import formatAddress from "../helpers/formatAddress"
import getGoogleStr from "../helpers/getGoogleStr"
import SocialIcons from "../SocialIcons/SocialIcons"

export default function HeroFooter({ siteData }) {
  return (
    <Container className="is-hidden-touch">
      <Wrapper>
        <WrapperLeft>
          <Column>
            <CTALink
              target="_blank"
              rel="noopener"
              href={getGoogleStr(
                siteData.name,
                siteData.street,
                siteData.city,
                siteData.zip,
                siteData.state
              )}
            >
              {" "}
              {formatAddress(
                siteData.state,
                siteData.street,
                siteData.city,
                siteData.zip
              )}
            </CTALink>
          </Column>
          <Column>
            <CTALink href={`tel:${siteData.phone}`}>
              Phone: {siteData.phone}{" "}
            </CTALink>
          </Column>
          <Column>
            <Link to="https://docksidebrewery.us4.list-manage.com/subscribe/post?u=d8ee0d625446f3c6eb4a0348b&id=0c24ddddbf">
              Email Sign Up
            </Link>
          </Column>
          <Column>
            <Link
              target="_blank"
              style={{ color: "white" }}
              to="https://dockside-brewery.square.site/"
              rel="noopener noreferrer"
            >
              Get Gift Cards
            </Link>
          </Column>
          <Column>
            <Link
              target="_blank"
              style={{ color: "white" }}
              to="https://dockwa.com/explore/destination/pwcyg5-dockside-marina-docksidebrewery?form=transient"
              rel="noopener noreferrer"
            >
              Dockwa
            </Link>
          </Column>
          <Column>
            <Link
              target="_blank"
              style={{ color: "white" }}
              to="https://www.snagaslip.com/north-east/long-island-sound-marinas/dockside-marina-at-dockside-brewery"
              rel="noopener noreferrer"
            >
              Snag a Slip
            </Link>
          </Column>
        </WrapperLeft>
        <WrapperRight>
          <SocialIcons links={siteData.links} />
        </WrapperRight>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  /* background: ${props =>
    props.theme.secondary ? props.theme.secondary : "black"}; */
    /* background: transparent; */
    background-color: rgb(0 86 125);
    padding: 0rem;
    padding-right: 2rem;
    padding-left: 2rem;
    position: fixed;
    width: 100%;
    z-index: 20;
    bottom: 0px;
  }
`

const Column = styled.div``
const CTALink = styled.a`
  color: white;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  &:hover {
    color: ${props => (props.theme.primary ? props.theme.primary : "black")};
    transition: all 0.3s;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WrapperLeft = styled.div`
    display: flex;
    > ${Column} {
      padding: 1.5rem 1rem;
      /* border-right: 3px solid #fff; */
      text-align: center;
      display: flex;
      align-items: center;
      font-weight: 500;
      a {
        display: flex;
        align-items: center;
        color:white;
  font-size: 0.85rem;

        &:hover {
          color: ${props =>
            props.theme.primary ? props.theme.primary : "black"};
          transition: all 0.3s;
        }
      }
`

const WrapperRight = styled.div`
  display: flex;
  border: none;
`
